import React from 'react'
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Links } from '../Common/CommonElement'
import aboutData from '../Data/AboutData'
import AboutImg from '../../Img/about.png'
import ProcessImg from '../../Img/process.png'
import {
  AboutData,
  AboutImage,
  AboutQuote,
  StepCard,
  StepData,
  StepImg,
  StepAnime
} from './AboutElement'

import {
  Container, Content, Head, Anime, DataHolder
} from '../Common/CommonElement'

const AboutUs = () => {
  return (
    <>
      <Container style={{
        background: '#FAFAFB',
      }}>
        {/* <Anime /> */}
        <Head style={{ textAlign: 'left', marginBottom: '20px' }}>About Us</Head>
        <Breadcrumbs style={{ marginTop: '-15px' }} aria-label="breadcrumb">
          <Links to='/'>Home</Links>

          <Typography color="textPrimary">About Us</Typography>
        </Breadcrumbs>
        <Content style={{
          margin: '40px 0'
        }}>
          <DataHolder>
            <AboutImage src={AboutImg} alt='Not Found' />
          </DataHolder>
          <DataHolder style={{
            flexDirection: "column"
          }}>
            <AboutQuote >{aboutData.aboutQuote}</AboutQuote>
            <AboutData>
              {
                aboutData.aboutDesc
              }
            </AboutData>
          </DataHolder>
        </Content>
        <Content style={{
          padding: '30px 0'
        }}>
          <div style={{
            fontWeight: '700',
            fontSize: '32px'
          }}>How we work ?</div>
        </Content>
        <Content style={{
          margin: '35px 0',
        }}>
          <DataHolder style={{
            display: 'grid',
          }}>
            {
              aboutData.steps.map((item, idx) => (
                <StepCard key={`steps ${idx}`} className={`step-${idx}`}>
                  <StepImg src={item.img} alt='not found' />
                  <StepData>{item.data}</StepData>
                  <StepAnime className='hover-bounce'>{idx + 1}</StepAnime>
                </StepCard>
              ))
            }
          </DataHolder>
          <DataHolder>
            <AboutImage src={ProcessImg} alt="not found" />
          </DataHolder>
        </Content>
      </Container>
    </>
  )
}

export default AboutUs
