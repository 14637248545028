import styled from 'styled-components'
import { Link as LinkR } from 'react-router-dom'

export const Container = styled.div`
  /* border: 2px solid red; */
  padding: 50px 80px;
  position: relative;
  @media screen and (max-width: 769px){
   padding: 10px 20px; 
  }
  @media screen and (max-width: 527px){
    padding: 10px;
  }

`

export const Content = styled.div`
  /* padding: 50px 10px; */
  /* border: 2px solid red; */
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 769px){
    display: flex;
    flex-direction: column;
  }
`

export const DataHolder = styled.div`
  /* border: 2px solid green; */
  position: relative; 
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;

  @media screen and (max-width: 769px){
    /* border: 2px solid red; */
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  @media screen and (max-width: 527px){
    width: 100%;
  }
`

export const HeadHolder = styled.div`
  /* padding-top: 20px; */
  /* border: 2px solid red; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`

export const HeadTop = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Icon = styled.img`
  animation: IconRound 15s linear infinite;

  @keyframes IconRound {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`

export const Title = styled.div`
  text-transform: uppercase;
  font-weight: 700;
  color: #0B9AD6;
  margin-left: 10px;
`

export const Head = styled.div`
  font-size: 48px;
  font-weight: 800;
  line-height: 58px;
  margin-bottom: 15px;

  @media screen and (max-width: 769px){
    margin-top: 10px;
    font-size: 44px;
  }
  @media screen and (max-width: 527px){
    font-size: 38px;
  }
`

export const Detail = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: rgba(0,0,0,.4);
  line-height: 30px;
  margin-bottom: 15px;
`

export const Links = styled(LinkR)`
  text-decoration: none;
  /* font-size: 1rem; */
  /* letter-spacing: .2em; */
  cursor: pointer;
  color: #000;

  &:hover{
    color: #0B9AD6;
  }
`

export const Anime = styled.div`
  opacity: .4;
  float: right;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background: linear-gradient(to bottom, #d68fd9 , rgba(255,255,255, .1));
  animation: rot 4s linear infinite;
  z-index: -1;

  @keyframes rot {
    0%{
      transform: rotateY(0deg);
      -webkit-transform: rotateY(0deg);
    }
    100%{
      transform: rotateY(360deg);
      -webkit-transform: rotateY(360deg);
    }
  }

  @media screen and (max-wdith: 769px) {
    display: none;
  }
`