import React from 'react'
// import Carousel from 'react-elastic-carousel'
import { Carousel } from 'antd';
import testimonialData from '../Data/TestimonialData'
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa'
import { ImQuotesRight } from 'react-icons/im'
import { Container, Content, DataHolder, Detail, Head, HeadHolder, HeadTop, Icon, Title } from '../Common/CommonElement'
import { ButtonHolder, Next, Prev, Quote, TestCard, TestDesignation, TestDetails, TestName } from './TestElement'
import { red } from '@material-ui/core/colors';
import './main.css'

const contentStyle = {
  height: '160px',
  color: '#fff',
  lineHeight: '160px',
  textAlign: 'center',
  background: '#364d79',
};


const Testimonial = () => {

  return (
    <>
      <Container style={{ background: '#F1F8FB' }}>
        {
          testimonialData.map((data, index) => (
            <HeadHolder key={index + 'teamHead'}>
              <HeadTop>
                <Icon src={data.icon} alt="Star Logo" />
                <Title>{data.title}</Title>
              </HeadTop>
              <Head>{data.head}</Head>
              {/* <Detail>{data.detail}</Detail> */}
            </HeadHolder>
          ))
        }
        {/* <Content > */}
        {/* <DataHolder> */}
        <div style={{
          display: 'grid',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
          <Carousel className='caro' autoplay style={{
            // position: 'relative',
            // placeContent: 'center',
            // display: 'flex',
            // justifyContent: 'center',
            // alignItems: 'center',
            // maxWidth: '500px',
            // minWidth: '200px',
            // textAlign: 'center',
            // overflow: 'visible',
            // padding: '10px'
          }}>
            {
              testimonialData.map((data, index) => (
                data.testList.map((data, index) => (
                  <div>
                    <TestCard key={index + 'testimonial'} >
                      <Quote><ImQuotesRight /></Quote>
                      <TestDetails>{data.desc}</TestDetails>
                      <TestName>{data.name}</TestName>
                      <TestDesignation>{data.designation}</TestDesignation>
                    </TestCard>
                  </div>
                ))
              ))
            }
          </Carousel>
        </div>
        {/* </DataHolder> */}
        {/* </Content > */}
      </Container >
    </>
  )
}

export default Testimonial

// {
//   testimonialData.map((data, index) => (
//     data.testList.map((data, index) => (
//       <TestCard key={index + 'testimonial'}>
//         <Quote><ImQuotesRight /></Quote>
//         <TestDetails>{data.desc}</TestDetails>
//         <TestName>{data.name}</TestName>
//         <TestDesignation>{data.designation}</TestDesignation>
//       </TestCard>
//     ))
//   ))

// }