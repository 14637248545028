import React, { useEffect } from 'react'
import { animateScroll as Scroll } from 'react-scroll';
import AboutUs from '../Components/About'
import HomeContainer from '../Container/HomeContainer'
import Firebase from '../firebase/firebase'

const About = () => {
  const toggleHome = () => {
    Scroll.scrollToTop()
  }

  useEffect(() => {
    toggleHome();
    startAnalytics()
  }, [])

  function startAnalytics() {
    Firebase.analytics().logEvent('about_us_view')
  }
  return (
    <>
      <HomeContainer>
        <AboutUs />
      </HomeContainer>
    </>
  )
}

export default About
