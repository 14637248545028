import React, { useEffect, useState } from 'react'
import ReactTooltip from "react-tooltip";
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Links } from '../Common/CommonElement'
import { CgCalendarDates } from 'react-icons/cg'
import { Link } from 'react-router-dom'
import { Container, Content, DataHolder, Head, Anime } from '../Common/CommonElement'
import {
  BlogHolder,
  BlogCard,
  BlogTitle,
  BlogCover,
  WriteHolder,
  WriteName,
  BlogDate,
  BlogData,
  WriterProfile,
} from './BlogComponent'
import { AiFillAndroid, AiFillApple, AiFillInfoCircle } from 'react-icons/ai'
// AiOutlineEye
// import { FaAngleLeft, FaAngleRight } from 'react-icons/fa'
import projectData from '../Data/ProjectData'
import blogData from '../Data/BlogData'
import { AppLink, ProjectData, ProjectDetails, ProjectTitle, TechList, Technology } from '../Project/ProjectElement'
import { CardHolder, ProjectCover, Card } from '../Project/AllProjectElement'
import { API_GET_ALL_BLOGS } from '../../apiCalling/apiTypes';
import apiCall from '../../apiCalling/apiCall';
import { admin } from '../../utils/constants';
import moment from 'moment';

const AllBlog = () => {

  const [allPosts, setAllPosts] = useState()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    getAllPosts()
  }, [])

  function getAllPosts() {
    setLoading(true)
    apiCall(API_GET_ALL_BLOGS, 'GET')
      .then(res => {
        setLoading(false)
        setAllPosts(res)
      })
      .catch(err => {
        setLoading(false)
      })
  }

  return (
    <Container>
      <Anime />
      <Head style={{ textAlign: 'Left', marginBottom: '20px' }}>All Blogs</Head>
      <Breadcrumbs style={{ marginTop: '-15px', marginBottom: '30px' }} aria-label="breadcrumb">
        <Links to='/'>Home</Links>
        <Typography color="textPrimary">Blogs</Typography>
      </Breadcrumbs>
      {allPosts && allPosts.success == true &&
        <Content>
          <DataHolder>
            {
              blogData.map((data, index) => (
                <BlogHolder key={index + 'blogholder'} >
                  {
                    allPosts.data.map((data, index) => (
                      <Link className='blog-redirect' to={{
                        pathname: '/blog',
                        blogData: data,
                        blogWriter: admin,
                      }}
                        // target='_blank'
                        key={index + 'blogcard'}>
                        <BlogCard >
                          <BlogCover src={data.cover} alt='Cover Img' />
                          <BlogData>
                            <WriteHolder>
                              <WriterProfile src={admin.writerProfile} alt='Profile' />
                              <WriteName>{admin.name}</WriteName>
                            </WriteHolder>
                            <BlogDate><CgCalendarDates className='date-icon' />{moment(data.createdAt).format('hh:mm, DD/MM/YYYY')}</BlogDate>
                          </BlogData>
                          <BlogTitle>{data.title}</BlogTitle>
                        </BlogCard>
                      </Link>
                    ))
                  }
                </BlogHolder>
              ))
            }
          </DataHolder>
        </Content>
      }
    </Container>
  )
}

export default AllBlog

                    // <Card key={index + 'projectCard'}>
                    //   <ProjectCover src={data.cover} alt="Project Cover" />
                    //   <ProjectData>
                    //   <div>
                    //     {data.title}
                    //   </div>
                    //   <div>
                    //     {data.desc}
                    //   </div>
                    //   </ProjectData>
                    // </Card>