import styled from 'styled-components'

export const AboutImage = styled.img`
  width:  100%;
  position: relative;
`

export const AboutData = styled.div`
  font-size: 18px;
  font-weight: 500;
  line-height: 32px;
`

export const AboutQuote = styled.div`
  padding: 10px 25px;
  margin-bottom: 10px;
  border-radius: 50px;
  background: #F4F8FC;
  font-weight: 500;
  font-size: 18px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
`

export const StepCard = styled.div`
  /* border: 2px solid red; */
  position: relative;
  background: #fff;
  border-radius: 10px;
  color: black;
  margin: 10px 0;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  width: 350px;
  overflow: hidden;

  &:nth-child(1) {
    transform: translateX(200px);
  }
  &:nth-child(2) {
    transform: translateX(150px);
  }
  &:nth-child(3) {
    transform: translateX(100px);
  }
  &:nth-child(4) {
    transform: translateX(50px);
  }
  &:nth-child(5) {
    transform: translateX(0px);
  }
  &:nth-child(6) {
    transform: translateX(-50px);
  }
  &:nth-child(7) {
    transform: translateX(-100px);
  }

  :hover{
    .hover-bounce{
      animation: bounce 2s ease;
    } 
  }

  @keyframes bounce{
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0) rotateZ(5deg);
    } 
   40% {transform: translateY(-30px) rotateZ(5deg);} 
   60% {transform: translateY(-15px) rotateZ(5deg);} 
  }

   @media screen and (max-width: 769px){
     &:nth-child(1),
     &:nth-child(2),
     &:nth-child(3),
     &:nth-child(4),
     &:nth-child(5),
     &:nth-child(6),
     &:nth-child(7) {
       transform: translateX(0);
     }
   }
`

export const StepImg = styled.img`
  background: #F4F8FC;
  padding: 10px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  /* border: 2px solid red; */
  color: black;
`

export const StepData = styled.div`
  /* border: 2px solid red; */
  font-size: 22px;
  font-weight: 600;
  color: black;
  position: relative;
  width: 100%;
`

export const StepAnime = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  opacity: .06;
  font-weight: 700;
  font-size: 66px;
  transform: rotateZ(5deg);
`