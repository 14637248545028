import React, { useEffect, useState } from 'react'
import renderHTML from 'react-render-html';
import { Anime, Container, Content, DataHolder, Head } from '../Components/Common/CommonElement';
import HomeContainer from '../Container/HomeContainer';
import BlogWriter from '../Components/Blog/BlogWriter'
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Links } from '../Components/Common/CommonElement'
import { animateScroll as Scroll } from 'react-scroll';


const BlogPage = ({ history }) => {

  const [blogData, setBlogData] = useState({})
  const [blogWriter, setBlogWriter] = useState('')

  useEffect(() => {
    history.location.blogData && setBlogData(history.location.blogData)
    history.location.blogWriter && setBlogWriter(history.location.blogWriter)
  }, [history.location.blogData, history.location.blogWriter])

  const toggleHome = () => {
    Scroll.scrollToTop()
  }

  useEffect(() => {
    toggleHome();
  }, [])

  console.log(blogData)

  return (
    <>
      <HomeContainer>
        <Container>
          <Anime />
          <Head style={{ textAlign: 'left', marginBottom: '20px' }}>Blog</Head>
          <Breadcrumbs style={{ marginTop: '-15px' }} aria-label="breadcrumb">
            <Links to='/'>Home</Links>

            <Typography color="textPrimary">Blog</Typography>
          </Breadcrumbs>
          <Content>
            <DataHolder style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '10px 50px',
            }}>
              <div style={{
                // border: '2px solid red',
                borderRadius: '10px',
                width: '100%',
                // height: '100px',
              }}>
                {
                  blogData.cover &&
                  <img
                    style={{
                      position: 'relative',
                      width: '100%',
                      // height: '450px',
                      objectFit: 'cover',
                    }}
                    src={blogData.cover}
                    alt="Cover Img"
                  />
                }
              </div>
              <div>
                {blogData.htmlRender &&
                  renderHTML(blogData.htmlRender)
                }
              </div>
              <BlogWriter blogWriter={blogWriter} />
            </DataHolder>
          </Content>
        </Container>
      </HomeContainer>
    </>
  )
}

export default BlogPage