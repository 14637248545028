import styled from 'styled-components'

export const TestCard = styled.div`
  /* border: 2px solid red; */
  position: relative;
  justify-self: center;
  align-self: center;
  background: #fff;
  border-radius: 10px;
  margin-left: 10px; 
  margin-top: 50px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 280px;
  transform: translateX(-10px);
  /* box-shadow: 0 4px 20px rgba(0,0,0,.05); */
`

export const Quote = styled.div`
  position: absolute;
  color: rgba(0,0,0,.1);
  font-size: 100px;
  top: -20px;
  right: 50%;
  /* transform: translateY(-35px); */
  animation: bounced 3s linear infinite;

  @keyframes bounced{
    0% { transform: translateY(-25px); }
    50% { transform: translateY(-35px); }
    100% { transform: translateY(-25px); }
  }

  @media screen and (max-width: 527px){
    display: none;
  }
`

export const TestDetails = styled.div`
  margin-top: 30px;
  color: rgba(0,0,0,.7);
  font-size: 18px;
  text-align: center;
  line-height: 30px;

  @media screen and (max-width: 521px ) {
    font-size: 13px;
  }
  @media screen and (max-width: 421px ) {
    font-size: 10px;
  }
`

export const TestName = styled.div`
  font-size: 18px;
  margin-top: 10px;
  font-weight: 800;
  @media screen and (max-width: 521px ) {
    font-size: 16px;
  }
  @media screen and (max-width: 521px ) {
    font-size: 14px;
  }
`

export const TestDesignation = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: rgba(0,0,0,.7);

  @media screen and (max-width: 521px ) {
    font-size: 14px;
  }
  @media screen and (max-width: 521px ) {
    font-size: 12px;
  }
`


export const ButtonHolder = styled.div`
  display: flex;
  justify-content: space-between;

  .arrow-icon{
    /* border: 2px solid red; */
    padding: 10px;
    background: #fff;
    font-size: 20px;
    margin-left: 10px;
    border-radius: 50%;
    color: #0B9AD6;
    box-shadow: 0 0 5px rgba(0,0,0,.4);
    transition: .3s ease;

    :hover{
      color: #fff;
      background: #0B9AD6;
    }
  }
`

export const Prev = styled.div``

export const Next = styled.div``