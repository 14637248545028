import heroImg from '../../Img/hero.png'

const heroData = [
  {
    head1: "We don't just work!",
    head2: "We work 'For You' & 'With You'",
    detail1: "Setup your online presence with us before your competitors do. We offers complete cost effective IT Solutions.",
    detail2: "Contact us now to chat more about your ideas.",
    button: 'Get In Touch',
    img: heroImg
  }
]

export default heroData