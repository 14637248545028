import React from "react";
import logo from "../../Img/logo.png";
import { GoLocation } from "react-icons/go";
import { BiPhoneCall } from "react-icons/bi";
import { AiFillMail } from "react-icons/ai";
import { Link } from "react-router-dom";
import { FaFacebookF, FaInstagram, FaLinkedinIn } from "react-icons/fa";
import { Container, Content } from "../Common/CommonElement";
import {
  Address,
  Anchor,
  BackWrold,
  CopyRight,
  Data,
  FooterBar,
  FooterData,
  Hover,
  Logo,
  PrivacyPolicy,
  SocialIcon,
  TagLine,
} from "./FooterElement";
import wrold from "../../Img/wrold.png";

const Footer = () => {
  return (
    <>
      <Container>
        <Hover />
        <Content>
          <BackWrold src={wrold} alt="Wrold" />
          <FooterData>
            <Data>
              <Link to="/">
                <Logo src={logo} alt="Logo" />
              </Link>
              <TagLine>
                SR DevOps is Web and Mobile App Development Company started in
                2020.
                <br />
                At SR DevOps we provide cost effective IT Solutions.
              </TagLine>
              <SocialIcon>
                <a
                  href={"https://www.facebook.com/thesrdevops"}
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaFacebookF className="social-icon" />
                </a>
                <a
                  href={"https://www.instagram.com/thesrdevops"}
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaInstagram className="social-icon" />
                </a>
                {/* <a href={data.fb} target='_blank' rel="noreferrer"><FaLinkedinIn className='social-icon' /></a> */}
              </SocialIcon>
            </Data>
            <Address>
              <div className="add-head">Address</div>
              <div className="add">
                <GoLocation className="add-icon" />
                1st Floor, Hanuman Complex, Kumbha Cir Rd, opp. Somila Tower,
                Bhilwara, Rajasthan 311001
              </div>
              <div className="add">
                <BiPhoneCall className="add-icon" />
                +91 8852050240
              </div>
              <div className="add">
                <AiFillMail className="add-icon" />
                business.srdevops@gmail.com
              </div>
            </Address>
          </FooterData>
        </Content>
        <FooterBar>
          <CopyRight>
            Copyright @2021 <span className="brandName">SR Devops</span> All
            rights reserved
          </CopyRight>
          <PrivacyPolicy>
            <Anchor>Privacy Policy</Anchor>
            <div> &nbsp; | &nbsp;</div>
            <Anchor>Terms &amp; Conditions</Anchor>
          </PrivacyPolicy>
        </FooterBar>
      </Container>
    </>
  );
};

export default Footer;
