import styled from 'styled-components'

export const BlogHolder = styled.div`
  /* border: 2px solid red; */
  margin: 30px 0;
  position: relative;
  display: grid;
  grid-template-columns: auto auto auto;
  grid-column-gap: 20px;
  grid-row-gap: 20px;

  @media screen and (max-width: 769px){ 
    grid-template-columns: auto auto;
  }
  @media screen and (max-width: 527px){
    grid-template-columns: auto;
  }

  .blog-redirect{
    text-decoration: none;
    color: #000;
  }
`

export const BlogCard = styled.div`
  position: relative;
  /* border: 2px solid red; */
  background: #fff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  transition: .5s ease;

  :hover{
    box-shadow: 0 0 10px rgba(0,0,0,.3);
    transform: translateY(-10px)
  }
`

export const BlogCover = styled.img`
  position: relative;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  /* border-radius: 10px; */
  width: 100%;
  height: 300px;
  object-fit: cover;
`
export const BlogData = styled.div`
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const WriteHolder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const WriteName = styled.div`
  margin-left: 5px;
`

export const WriterProfile = styled.img`
  width: 40px;
  border-radius: 50%;
`

export const BlogDate = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 10px 10px 10px;

  .date-icon{
    margin-right: 5px;
    color: #0B9AD6;
    font-size: 20px;
  }
`

export const BlogTitle = styled.div`
  margin: 10px;
  font-size: 24px;
  font-weight: 800;
  text-align: center;
`


// ---------- Blog Writer Component ----------

export const WriterHolder = styled.div`
  background: #F6F8F8;
  border-radius: 10px;
  padding: 20px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70%;
  /* width: 75%; */
  /* float: center; */
  /* transform: translate(-50%,-50%); */
`

export const ProfileContainer = styled.div`
  
  padding: 20px;
  width: 250px;
`

export const Profile = styled.img`
  border-radius: 50%;
  position: relative;
  width: 100%;
`

export const DataContainer = styled.div`
  padding: 20px;
`


export const WriterName = styled.div`
  text-align: left;
  font-weight: 600;
  font-size: 24px;

  ::before {
    content: 'xyz';
    position: absolute;
    width: 75px;
    color: transparent;
    border-bottom: 3px solid #0B9AD6;
  }
`

export const WriterDesc = styled.div`
  text-align: left;
  margin-top: 15px;
  
`