import styled from 'styled-components'

export const FooterData = styled.div`
  /* border: 2px solid red; */
  display: flex;
  justify-content: center;
  text-align: left;
  margin: 60px 0;

  @media screen and (max-width: 527px){ 
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`
export const Data = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
`

export const SocialIcon = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .social-icon{ 
    cursor: pointer;
    padding: 5px;
    margin-right: 10px;
    background: rgba(0,0,0,.5);
    border-radius: 5px;
    color: #fff;
    font-size: 1.2rem;
    font-weight: bold;

    :hover{
      background: #0B9AD6;
    }
  }
`

export const Address = styled.div`
  /* border: 2px solid red; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
  line-height: 30px;

  .add-head{
    font-size: 20px;
    font-weight: 800;
  }

  .add{
    margin-top: 10px;
  }

  .add-icon{
    color: #0B9AD6;
    margin-right: 10px;
  }
`

export const Logo = styled.img`
  margin-top: 10px;
  position: relative;
  width: 150px;
`

export const TagLine = styled.div`
  margin-top: 10px;
  color: rgba(0,0,0,.7);
  font-weight: 600;
  line-height: 30px;
`

export const BackWrold = styled.img`
  /* border: 2px solid red; */
  position: absolute;
  z-index: -2;
  animation: wroldMove 7s linear infinite;

  @keyframes wroldMove {
    0%{ transform: translateX(-30px); }
    50%{ transform: translateX(30px); }
    100%{ transform: translateX(-30px); }
  }

  @media screen and (max-width: 527px){
    display: none;
  }
`
export const Hover = styled.div`
  position: absolute;
  z-index: -1;
  background: rgba(255, 255, 255,.7);
  /* background: rgba(254, 252, 248,.8); */
  /* background: rgba(239, 157, 203,.15); */
  /* background: #FEFCF8; */
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;

  @media screen and (max-width: 527px){
    display: none;
  }
`


export const FooterBar = styled.div`
  margin-top: 10px;
  /* border: 2px solid red; */
  display: flex;
  justify-content: space-between;
  font-weight: 600;

  @media screen and (max-width: 527px){
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`

export const CopyRight = styled.div`
  color: rgba(0,0,0,.7);

  .brandName{
    color: #0B9AD6;
    font-weight: 800;
  }

  @media screen and (max-width: 527px){
    margin-bottom: 5px;
  }
`

export const PrivacyPolicy = styled.div`
  display: flex;
`

export const Anchor = styled.a`
  cursor: pointer;

  :hover{
    color: #0B9AD6;
  }
`