import React from 'react'
import serviceData from '../Data/ServiceData'
import { AiOutlineCheck } from 'react-icons/ai'
import {
  Container,
  Content,
  DataHolder,
  HeadHolder,
  Icon,
  Title,
  Head,
  Detail,
  HeadTop
} from '../Common/CommonElement'
import {
  ListHolder,
  ServiceImg,
  ListItem,
  ServiceImgHolder
} from './ServiceElement'

const Service = () => {


  return (
    <>
      <Container>
        {
          serviceData.map((data, index) => (
            <HeadHolder key={index + 'serviceHead'}>
              <HeadTop>
                <Icon src={data.icon} alt="Star Logo" />
                <Title>{data.title}</Title>
              </HeadTop>
              <Head>{data.head}</Head>
              {/* <Detail>{data.detail}</Detail> */}
            </HeadHolder>
          ))
        }
        <Content>
          {
            serviceData.map((data, index) => (
              <DataHolder key={index + 'serviceLeft'}>
                <ListHolder>
                  {
                    data.serviceList.map((data, index) => (
                      <ListItem key={index + 'listItem'}><AiOutlineCheck className='checkIcon' />{data}</ListItem>
                    ))
                  }
                </ListHolder>
              </DataHolder>
            ))
          }
          {
            serviceData.map((data, index) => (
              <ServiceImgHolder key={index + 'serviceRight'}>
                <ServiceImg src={data.img} alt="Service Img" />
              </ServiceImgHolder>
            ))
          }
        </Content>
      </Container>
    </>
  )
}

export default Service
