import React, { useState } from 'react'
import './main.css'
import { AiOutlineCheck } from 'react-icons/ai'
import {
  Container,
  Content,
  DataHolder,
  Head,
  Anime
} from '../Common/CommonElement'
import contactImg from '../../Img/contact.png'
import { ContactImg, Form, FormContainer, Input, TextArea, Button } from './ContactElement'
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Links } from '../Common/CommonElement'
import {
  RadiusUpleftOutlined,
  RadiusUprightOutlined,
  RadiusBottomleftOutlined,
  RadiusBottomrightOutlined,
} from '@ant-design/icons';
import { notification, Divider, Space } from 'antd';
import apiCall from '../../apiCalling/apiCall'
import { API_SEND_QUERY } from '../../apiCalling/apiTypes'

const ContactUs = (props) => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [message, setMessage] = useState('')

  const handleNameChange = ev => {
    setName(ev.target.value)
  }
  const handleEmailChange = ev => {
    setEmail(ev.target.value)
  }
  const handlePhoneChange = ev => {
    setPhone(ev.target.value)
  }
  const handleMessageChange = ev => {
    setMessage(ev.target.value)
  }

  const openNotification = (message, subMessage) => {
    notification.info({
      message: message,
      description: subMessage,
      placement: 'bottomRight',
    });
  };

  const onSubmit = () => {
    if (name == '') { openNotification('Alert!', "Please enter your name."); return }
    if (email == '') { openNotification('Alert!', "Please enter your email address."); return }
    if (phone == '') { openNotification('Alert!', "Please enter your phone number."); return }
    if (message == '') { openNotification('Alert!', "Please enter message."); return }

    let data = {
      name: name,
      email: email,
      phone: phone,
      message: message
    }

    apiCall(API_SEND_QUERY, 'POST', data)
      .then(res => {
        openNotification('Success!', "We have received your query, We will contact you very soon.");
      })
      .catch(err => {
        openNotification('Error!', err);
      })

  }


  return (
    <>
      <Container >
        {props.pathName !== '/' ?
          <>
            <Anime />
            <Head style={{ textAlign: props.pathName === '/' ? 'center' : 'left', marginBottom: '40px' }}>Contact Us</Head>
            <Breadcrumbs style={{ marginTop: '-15px' }} aria-label="breadcrumb">
              <Links to='/'>Home</Links>
              <Typography color="textPrimary">Contact Us</Typography>
            </Breadcrumbs>
          </>
          :
          <>
            <Head style={{ textAlign: props.pathName === '/' ? 'center' : 'left', marginBottom: '40px' }}>Tell Us About Your Project</Head>
          </>
        }

        <Content style={{ marginTop: '20px' }} >
          <DataHolder className="dataholder">
            <ContactImg src={contactImg} alt='Contact Image' />
          </DataHolder>
          <DataHolder className="dataholder">
            <FormContainer>
              <Form>
                <Input value={name} onChange={handleNameChange} placeholder="Your Name" />
                <Input value={email} onChange={handleEmailChange} placeholder="Your email address" />
                <Input value={phone} onChange={handlePhoneChange} placeholder="Your phone number" />
                <TextArea value={message} onChange={handleMessageChange} placeholder="Write your message..." />
                <Button onClick={() => onSubmit()} type='button' ><AiOutlineCheck className="icon" />Send Message</Button>
              </Form>
            </FormContainer>
          </DataHolder>
        </Content>
      </Container>
    </>
  )
}

export default ContactUs
