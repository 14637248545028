// import Carousel from 'react-elastic-carousel'
import { Carousel } from 'antd';
import React from 'react';
import { AiFillAndroid, AiFillApple, AiFillInfoCircle, AiOutlineEye } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import ReactTooltip from "react-tooltip";
import { Container, Detail, Head, HeadHolder, HeadTop, Icon, Title } from '../Common/CommonElement';
import projectData from '../Data/ProjectData';
import './main.css';
import { AppLink, PreviewAll, PreviewAllHolder, ProjectCard, ProjectCover, ProjectData, ProjectDetails, ProjectTitle, Round, Square, TechList, Technology } from './ProjectElement';

// const Project = () => {
class Project extends React.Component {

  constructor(props) {
    super(props);
    this.state = {

    };
  }

  render() {
    return (
      // <>
      <Container id='projects'>
        <Round />
        <Square />
        {
          projectData.map((data, index) => (
            <HeadHolder key={index + 'teamHead'}>
              <HeadTop>
                <Icon src={data.icon} alt="Star Logo" />
                <Title>{data.title}</Title>
              </HeadTop>
              <Head>{data.head}</Head>
              <Detail>{data.detail}</Detail>
            </HeadHolder>
          ))
        }
        {/* <Content> */}
        <Carousel className='project-caro' autoplay dots={false}  >
          {
            this.props.data.map((data, index) => (
              <div>
                <ProjectCard key={index + 'project'}>
                  <ProjectCover src={data.cover} alt='Cover Img' />
                  <ProjectData>
                    <ProjectTitle>{data.title}</ProjectTitle>
                    <ProjectDetails>
                      {data.shortDesc}
                      <AiFillInfoCircle data-tip data-for="main" className='info-icon' />
                      <ReactTooltip id="main" data-multiline="true" place="bottom" type="dark" effect="solid">
                        <span>
                          {data.desc}
                        </span>
                      </ReactTooltip>
                    </ProjectDetails>
                    <Technology>Technology: &nbsp;
                      {
                        data.technology.map((data, index) => (
                          <TechList key={index + data}>{data} &nbsp;</TechList>
                        ))
                      }
                    </Technology>
                    <AppLink>
                      <a className='applink' href={data.andriodLink} ><AiFillAndroid /></a>
                      <a className='applink' href={data.iosLink}><AiFillApple /></a>
                    </AppLink>
                  </ProjectData>
                </ProjectCard>
              </div>
            ))
          }
        </Carousel>
        {/* <DataHolder> */}
        {/* <Carousel
            easing="cubic-bezier(1,.15,.55,1.54)"
            tiltEasing="cubic-bezier(0.110, 1, 1.000, 0.210)"
            transitionMs={700}
            ref={ref => (this.carousel = ref)}
          >
            {
              projectData.map((data, index) => (
                data.projectList.map((data, index) => (
                  <ProjectCard key={index + 'project'}>
                    <ProjectCover src={data.cover} alt='Cover Img' />
                    <ProjectData>
                      <ProjectTitle>{data.title}</ProjectTitle>
                      <ProjectDetails>
                        {data.desc}
                        <AiFillInfoCircle data-tip data-for="main" className='info-icon' />
                        <ReactTooltip id="main" data-multiline="true" place="bottom" type="dark" effect="solid">
                          <span>
                            {data.fullDesc}
                          </span>
                        </ReactTooltip>
                      </ProjectDetails>
                      <Technology>Technology: &nbsp;
                        {
                          data.technology.map((data, index) => (
                            <TechList key={index + data}>{data} &nbsp;</TechList>
                          ))
                        }
                      </Technology>
                      <AppLink>
                        <a className='applink' href={data.andriodLink} ><AiFillAndroid /></a>
                        <a className='applink' href={data.iosLink}><AiFillApple /></a>
                      </AppLink>
                    </ProjectData>
                  </ProjectCard>
                ))
              ))
            }
          </Carousel> */}
        {/* </DataHolder> */}
        {/* </Content> */}
        {/* <ButtonHolder>
          <Prev onClick={() => this.carousel.slidePrev()}><FaAngleLeft className='arrow-icon' /></Prev>
          <Next onClick={() => this.carousel.slideNext()}><FaAngleRight className='arrow-icon' /></Next>
        </ButtonHolder> */}
        <PreviewAllHolder>
          <PreviewAll>
            <Link to='/projects' className='link-effect'>
              <AiOutlineEye className='eye-icon' />Check Out Our All Projects
            </Link>
          </PreviewAll>
        </PreviewAllHolder>
      </Container>
      // </>
    )
  }
}


export default Project