import serviceImg from '../../Img/service.png'
import starIcon from '../../Img/star-icon.png'

const serviceData = [
  {
    icon: starIcon,
    title: 'Services',
    head: 'What We Offers?',
    detail: 'Our diverging solutions for specific business needs',
    serviceList: [
      "Android App Development",
      "iOS App Development",
      "Web Development",
      "E-Commerce Solutions",
      "API Integration",
      "UI/UX Design",
      "Graphics Designing",
      "Wordpress Devlopment",
    ],
    img: serviceImg
  }
]

export default serviceData