import styled from 'styled-components'

export const ContactImg = styled.img`
  position: relative;
  width: 100%;
`

export const FormContainer = styled.div`
  position: relative;
  padding: 30px 40px;
  background: #fff;
  box-shadow: 0 0 10px rgba(0,0,0,.15);
  width: 80%;

  @media screen and (max-width: 527px){
    padding: 10px;
  }
`

export const Form = styled.form`
  display: flex;
  flex-direction: column;
`

export const Input = styled.input`
  border: none;
  border-radius: 10px;
  margin: 10px 0;
  height: 50px;
  background: rgba(0,0,0,.06);
  padding-left: 10px;
  font-size: 15px;

  :focus{
    outline: none;
    border: 1px solid #0B9AD6;
    background: #fff;
  }
`

export const TextArea = styled.textarea`
  border: none;
  border-radius: 10px;
  margin: 10px 0;
  height: 150px;
  background: rgba(0,0,0,.06);
  padding: 10px;
  font-size: 15px;

  :focus{
    outline: none;
    border: 1px solid #0B9AD6;
    background: #fff;
  }
`

export const Button = styled.button`
  border: none;
  border-radius: 10px;
  padding: 15px;
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-weight: 700;
  background: #0B9AD6;
  font-size: 16px;
  margin-top: 20px;
  transition: .3s ease;
  cursor: pointer;

  .icon {
    margin-right: 5px;
    font-size: 24px;
  }

  :focus{
    outline: none;
  }

  :hover{
    color: #0B9AD6;
    background: #fff;
    box-shadow: 0 0 5px rgba(0,0,0,.2);
    transform: translateY(-10px);
  }
`