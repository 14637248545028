import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom';
import Home from './Pages/Home';
import BlogPage from './Pages/BlogPage';
import Contact from './Pages/Contact';
import About from './Pages/About';
import Projects from './Pages/Projects';
import AllBlogs from './Pages/AllBlogs'
import 'antd/dist/antd.css';


function App() {
  return (
    <Router>
      <Switch>
        <Route path='/' exact component={Home} />
        <Route path='/blog' exect component={BlogPage} />
        <Route path='/about' exect component={About} />
        <Route path='/contact' exect component={Contact} />
        <Route path='/projects' exect component={Projects} />
        <Route path='/allblog' exect component={AllBlogs} />
      </Switch>
    </Router>
  );
}

export default App;