import Step from "../../Img/step.png";

const aboutData = {
  aboutQuote: "#Your Own Software Solution...",
  aboutDesc:
    "SR DevOps is Web and Mobile App Development Company started in 2020. At SR DevOps we provide cost effective IT Solutions. We believe in building your idea into reality. We try to make your project feature rich, visually stunning and highly responsive. Our vision is to deliver more and more quality projects to our clients. We are sorted with a team of experts that are aligned with your future needs.",
  steps: [
    {
      data: "Discusstion",
      img: Step,
    },
    {
      data: "BrainStorming",
      img: Step,
    },
    {
      data: "Documentation",
      img: Step,
    },
    {
      data: "Development",
      img: Step,
    },
    {
      data: "Testing",
      img: Step,
    },
    {
      data: "Delivery",
      img: Step,
    },
    {
      data: "Maintenance",
      img: Step,
    },
  ],
};

export default aboutData;
