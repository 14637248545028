import React, { useEffect } from 'react'
import { animateScroll as Scroll } from 'react-scroll';
import ContactUs from '../Components/ContactUs'
import Firebase from '../firebase/firebase'

import HomeContainer from '../Container/HomeContainer'

const Contact = () => {
  const toggleHome = () => {
    Scroll.scrollToTop()
  }

  useEffect(() => {
    startAnalytics()
    toggleHome();
  }, [])

  function startAnalytics() {
    Firebase.analytics().logEvent('contact_us_view')
  }

  return (
    <>
      <HomeContainer>
        <ContactUs />
      </HomeContainer>
    </>
  )
}

export default Contact
