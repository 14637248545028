import React from 'react'
import {
  DataContainer,
  Profile,
  ProfileContainer,
  WriterDesc,
  WriterHolder,
  WriterName
} from './BlogComponent'

const BlogWriter = ({ blogWriter }) => {
  return (
    <>
      <WriterHolder>
        <ProfileContainer>
          <Profile src={blogWriter.writerProfile} />
        </ProfileContainer>
        <DataContainer>
          <WriterName>{blogWriter.name}</WriterName>
          <WriterDesc>{blogWriter.desc}</WriterDesc>
        </DataContainer>
      </WriterHolder>
    </>
  )
}

export default BlogWriter