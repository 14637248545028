import starIcon from "../../Img/star-icon.png";

const testimonialData = [
  {
    icon: starIcon,
    title: "Testimonials",
    head: "What Our Cliets Are Saying?",
    detail:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    testList: [
      {
        name: "Mahesh Saini",
        designation: "Business Website",
        desc: "I just wanted to share a quick note and let you know that you guys do a really good job. I’m glad I decided to work with you. I never have any problem at all..",
      },
      {
        name: "Vipin Modi",
        designation: "Service Provider App",
        desc: "Team at SR DevOps did a terrific job of app development for us. Not only did they match the design exactly, but they were fast, responsive, and very easy to communicate with.",
      },
    ],
  },
];

export default testimonialData;
