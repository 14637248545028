import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import React, { useEffect, useState } from 'react';
import { AiFillAndroid, AiFillApple, AiFillInfoCircle } from 'react-icons/ai';
import ReactTooltip from "react-tooltip";
import apiCall from '../../apiCalling/apiCall';
import { API_GET_ALL_PROJECTS } from '../../apiCalling/apiTypes';
import { Container, Content, DataHolder, Head, Links } from '../Common/CommonElement';
import projectData from '../Data/ProjectData';
import { Card, CardHolder, ProjectCover } from './AllProjectElement';
import { AppLink, ProjectData, ProjectDetails, ProjectTitle, TechList, Technology } from './ProjectElement';

const AllProject = () => {

  const [allProjects, setAllProjects] = useState()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    getAllProjects()
  }, [])

  function getAllProjects() {
    setLoading(true)
    apiCall(API_GET_ALL_PROJECTS, 'GET')
      .then(res => {
        setLoading(false)
        setAllProjects(res)
      })
      .catch(err => {
        setLoading(false)
      })
  }

  return (
    <Container>
      {/* <Anime /> */}
      <Head style={{ textAlign: 'Left', marginBottom: '20px' }}>All Projects</Head>
      <Breadcrumbs style={{ marginTop: '-15px', marginBottom: '30px' }} aria-label="breadcrumb">
        <Links to='/'>Home</Links>
        <Typography color="textPrimary">Projects</Typography>
      </Breadcrumbs>
      {
        allProjects && allProjects.success == true &&
        <Content>
          <DataHolder>
            {
              projectData.map((data, index) => (
                <CardHolder key={index + 'blogholder'}>
                  {
                    allProjects.data.map((data, index) => (
                      <Card key={index + 'project'}>
                        <ProjectCover src={data.cover} alt='Cover Img' />
                        <ProjectData>
                          <ProjectTitle>{data.title}</ProjectTitle>
                          <ProjectDetails>
                            {data.shortDesc}
                            <AiFillInfoCircle data-tip data-for="main" className='info-icon' />
                            <ReactTooltip id="main" data-multiline="true" place="bottom" type="dark" effect="solid">
                              <span>
                                {data.desc}
                              </span>
                            </ReactTooltip>
                          </ProjectDetails>
                          <Technology>Technology: &nbsp;
                            {
                              data.technology.map((data, index) => (
                                <TechList key={index + data}>{data} &nbsp;</TechList>
                              ))
                            }
                          </Technology>
                          <AppLink>
                            <a className='applink' href={data.andriodLink} ><AiFillAndroid /></a>
                            <a className='applink' href={data.iosLink}><AiFillApple /></a>
                          </AppLink>
                        </ProjectData>
                      </Card>
                    ))
                  }
                </CardHolder>
              ))
            }
          </DataHolder>
        </Content>
      }
    </Container>
  )
}

export default AllProject
                    // <Card key={index + 'projectCard'}>
                    //   <ProjectCover src={data.cover} alt="Project Cover" />
                    //   <ProjectData>
                    //   <div>
                    //     {data.title}
                    //   </div>
                    //   <div>
                    //     {data.desc}
                    //   </div>
                    //   </ProjectData>
                    // </Card>