import React, { useState, useEffect } from 'react'
import Hero from '../Components/Hero'
import Project from '../Components/Project'
import Service from '../Components/Service'
import Team from '../Components/Team'
import Testimonial from '../Components/Testimonial'
import Blog from '../Components/Blog'
import HomeContainer from '../Container/HomeContainer'
import ContactUs from '../Components/ContactUs'
import { useLocation } from 'react-router'
import apiCall from '../apiCalling/apiCall'
import { API_GET_HOME_DATA } from '../apiCalling/apiTypes'
import Firebase from '../firebase/firebase'

const Home = ({ history }) => {

  const [pathName, setPathName] = useState('')
  const [loading, setLoading] = useState(false)
  const [homeData, setHomeData] = useState()

  let location = useLocation()

  useEffect(() => {
    location.pathname && setPathName(location.pathname)
  }, [location.pathname])

  useEffect(() => {
    startAnalytics()
    getHomeData()
  }, [])

  function getHomeData() {
    setLoading(true)
    apiCall(API_GET_HOME_DATA, 'GET')
      .then(res => {
        setLoading(false)
        setHomeData(res)
      })
      .catch(err => {
        setLoading(false)
      })
  }

  function startAnalytics() {
    Firebase.analytics().logEvent('home_view')
  }

  return (
    <>
      {
        // homeData && homeData.success == true &&
        <HomeContainer>
          <Hero />
          <Service />
          <Testimonial />
          {homeData && homeData.success == true && homeData.data.projects.length > 0 &&
            <Project data={homeData.data.projects || []} />
          }
          {homeData && homeData.success == true && homeData.data.blogs.length > 0 &&
            <Blog data={homeData.data.blogs || []} />
          }
          {/* <Team /> */}
          <ContactUs pathName={pathName} />
        </HomeContainer>
      }
    </>
  )
}

export default Home