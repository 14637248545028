import styled from 'styled-components'
import { Link as LinkS } from 'react-scroll'
import { Link as LinkR } from 'react-router-dom'

export const Nav = styled.nav`
  /* border: 2px solid red; */
  background:${({ scrollNav }) => (scrollNav ? '#fff' : 'transparent')};
  /* background: #fff; */
  color: #000;
  height: 80px;
  /* width: 100%; */
  /* min-height: -80px; */
  font-size: 1rem;
  /* position: relative; */
  position:${({ scrollNav }) => (scrollNav ? 'sticky' : 'relative')};
  top: 0;
  /* position: sticky; */
  box-shadow: 0 0 4px rgba(0,0,0,.2);
  z-index: 999;
`
export const NavbarContainer = styled.div`
  /* border: 2px solid red; */
  /* position: relative; */
  padding: 0 80px;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 769px){
    padding: 0 10px;
  }
`

export const Brand = styled.div`
  /* border: 2px solid red; */
  justify-self: center;
  align-self: center;
  place-content: center;
  width: 200px;
`

export const NavLogo = styled.img`
  width: 100%;
  cursor: pointer;
`
export const MoboIcon = styled.div`
  background: rgba(0,0,0, .05);
  height: 50px;
  width: 50px;
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  display: none;

  @media screen and (max-width: 769px){
    display: flex;
  }
`

export const Hamburger = styled.div`
  /* background: #0B9AD6; */
  background: ${({ navOpen }) => (navOpen ? 'transparent' : '#0B9AD6')};
  border-radius: 2px;
  width: 35px;
  height: 3px;
  /* display: ${({ navOpen }) => (navOpen ? 'none' : 'block')};  */
  transition: .3s ease;


  ::after{
    content: "";
    position: absolute;
    background: #0B9AD6;
    color: #000;
    border-radius: 2px;
    width: 35px;
    height: 3px;
    transform: translateY(10px);
    transform: ${({ navOpen }) => (navOpen ? 'rotate(-45deg)' : 'block')};
    transition: .3s ease;

  }
  ::before{
    content: "";
    position: absolute;
    background: #0B9AD6;
    border-radius: 2px;
    width: 35px;
    height: 3px;
    transform: translateY(-10px);
    transform: ${({ navOpen }) => (navOpen ? 'rotate(45deg)' : 'block')};
    transition: .3s ease;

  }
`


export const NavMenu = styled.ul`
  background: #fff;
  display: flex;
  
  @media screen and (max-width: 769px){
    /* border: 2px solid red; */
    display: ${({ navOpen }) => (navOpen ? 'flex' : 'none')};
    flex-direction: column;
    position: absolute;
    top: 80px;
    left: 0;
    left: 0;
    margin: 0;  
    width: 100vw;
    z-index: 999;
    padding: 10px;
  }
`

export const NavItem = styled.li`
  /* border: 2px solid blue; */
  list-style-type: none;
  text-align: center;
  justify-content: center;
  padding-left: 1.5rem;
  
  @media screen and (max-width: 769px){
    /* justify-content: flex-start; */
    /* align-items: flex-start; */
    text-align: left;
    padding: 0;
  }
`
export const NavLinks = styled(LinkS)`
  /* border: 2px solid green; */
  text-decoration: none;
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: .2em;
  cursor: pointer;
  color: #000;

  &:hover{
    color: #0B9AD6;
  }
`

export const Links = styled(LinkR)`
  text-decoration: none;
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: .2em;
  cursor: pointer;
  color: #000;

  &:hover{
    color: #0B9AD6;
  }
`

export const TopIcon = styled.div`
  box-shadow: 0 0 5px rgba(0,0,0,.3);
  font-size: 30px;
  position: absolute;
  display: flex;
  opacity:${({ scrollNav }) => (scrollNav ? '1' : '0')};
  justify-content: center;
  align-items: center;
  position: fixed;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  bottom:${({ scrollNav }) => (scrollNav ? '25px' : '0')};
  right: 20px;
  transform: rotate(90deg);
  transition: .3s ease;
  color: #fff;
  background: #272727;

  :hover{
    transform: translateY(-10px) rotate(90deg);
    background: #0B9AD6;

  }
`