import React, { useEffect } from 'react'
import AllBlog from '../Components/Blog/AllBlog'
import HomeContainer from '../Container/HomeContainer'
import { animateScroll as Scroll } from 'react-scroll';

const AllBlogs = () => {
  const toggleHome = () => {
    Scroll.scrollToTop()
  }

  useEffect(() => {
    toggleHome();
  }, [])

  return (
    <>
      <HomeContainer>
        <AllBlog />
      </HomeContainer>
    </>
  )
}

export default AllBlogs
