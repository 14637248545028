import React, { useEffect } from 'react'
import { animateScroll as Scroll } from 'react-scroll';
import AllProject from '../Components/Project/AllProject'
import HomeContainer from '../Container/HomeContainer'

const Projects = () => {
  const toggleHome = () => {
    Scroll.scrollToTop()
  }

  useEffect(() => {
    toggleHome();
  }, [])
  return (
    <>
      <HomeContainer>
        <AllProject />
      </HomeContainer>
    </>
  )
}

export default Projects
