import starIcon from '../../Img/star-icon.png'
import blog1 from '../../Img/blog1.jpg'
import user1 from '../../Img/user1.jpg'

const blogData = [
  {
    icon: starIcon,
    title: 'Our Blog',
    head: 'Check Out Our Blogs',
    detail: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    blogList: [
      {
        title: 'Six Ways to Make Smarter Decision',
        date: "April 30, 2020",
        writer: {
          name: 'Alex Morgan',
          writerProfile: user1,
          desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna ali. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.',
        },
        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna ali. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.',
        cover: blog1,
        htmlRender: '<h1>Heloo Blogger</h1>',
      },
      {
        title: 'Six Ways to Make Smarter Decision',
        date: "April 30, 2020",
        writer: {
          name: 'Alex Morgan',
          writerProfile: user1,
          desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna ali. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.',
        },
        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna ali. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.',
        cover: blog1,
        htmlRender: '<h1>Heloo Blogger</h1>',
      },
      {
        title: 'Six Ways to Make Smarter Decision',
        date: "April 30, 2020",
        writer: {
          name: 'Alex Morgan',
          writerProfile: user1,
          desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna ali. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.',
        },
        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna ali. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.',
        cover: blog1,
        htmlRender: '<h1>Heloo Blogger</h1>',
      },
    ]
  }
]

export default blogData