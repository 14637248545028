export default async (url, apiType, payload) => {
    return new Promise(async (resolve, reject) => {
        if (apiType == 'GET') {
            getApiCall(url, resolve, reject)
        }
        else {
            postApiCall(url, apiType, payload, resolve, reject)
        }
    })
}

export async function getApiCall(url, resolve, reject) {

    try {
        fetch(url, {
            method: 'GET',
        })
            .then(async response => {
                let resJson = await response.json()
                if (response.status === 200) {
                    resolve(resJson);
                }
                else {
                    reject(resJson);
                }
            })
            .catch(err => {
                reject({ 'Error': 'Something went wrong! Please try again in a moment.' });
            });
    } catch (err) {
        reject({ 'Error': 'Something went wrong! Please try again in a moment.' });
    }
}

async function postApiCall(url, apiType, payload, resolve, reject) {

    let formData

    if (Object.keys(payload).length === 0) {

    } else {
        formData = await getFormData(payload)
    }

    try {

        fetch(url, {
            method: apiType,
            body: formData
        })
            .then(async response => {
                let resJson = await response.json()
                if (response.status === 200) {
                    resolve(resJson);
                }
                else {
                    reject(resJson);
                }
            })
            .catch(err => {
                reject({ 'Error': 'Something went wrong! Please try again in a moment.' });
            });

    } catch (err) {
        reject({ 'Error': 'Something went wrong! Please try again in a moment.' });
    }
}

function getFormData(data) {

    let formData = new FormData();

    for (const [key, value] of Object.entries(data)) {
        if (key === 'installments') {
            formData.append(`${key}`, JSON.stringify(value))
        }
        else if (key !== 'installments' && Array.isArray(value) == true) {
            for (var i = 0; i < value.length; i++) {
                formData.append(`${key}`, value[i]);
            }
        }
        else {
            formData.append(`${key}`, value);
        }
    }

    return formData
}
