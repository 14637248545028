import styled from 'styled-components'

export const CardHolder = styled.div`
  /* border: 2px solid red; */
  position: relative;
  display: grid;
  grid-template-columns: auto auto auto;
  grid-column-gap: 15px;
  grid-row-gap: 25px;

  @media screen and (max-width: 527px){
    grid-template-columns: auto;
  }
`

export const Card = styled.div`
  /* border: 2px solid red; */
  position: relative;
  border-radius: 10px;
  box-shadow: 0 0 4px rgba(0,0,0,.15);
  display: flex;
  flex-direction: column;
  transition: .5s ease;
`

export const ProjectCover = styled.img`
  position: relative;
  width: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  height: 300px;
  object-fit: cover;
`

export const ProjectData = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
`