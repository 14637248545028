import styled from 'styled-components'

export const CardHolder = styled.div`
  /* border: 2px solid red; */
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-column-gap: 20px;

  @media screen and (max-width: 769px){
    grid-template-columns: auto auto;
    grid-row-gap: 20px;
  }
  @media screen and (max-width: 527px){
    grid-template-columns: auto;
  }
`


export const TeamCard = styled.div`
  /* border: 2px solid red; */
  display: flex;
  flex-direction: column;
`
export const ImgHolder = styled.div`
  position: relative;

  .social-icon-holder{
    transition: .3s ease;
    opacity: 0;   
  }
  :hover{
    .social-icon-holder{
      display: block;
      transform: translateY(-10px);
      opacity: 1;
    }
  }
`

export const TeamImg = styled.img`
  position: relative;
  width: 100%;
  height: 500px;
  Object-fit : cover;
`

export const MemberName = styled.div`
  font-size: 24px;
  font-weight: 800;
  margin: 10px 0 5px 0;
`

export const Designation = styled.div`
  font-weight: 600;
  color: #0B9AD6;
`
export const SocialIcon = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;

  .connect-icon{
    padding: 7px;
    margin-right: 10px;
    background: #fff;
    border-radius: 50%;
    color: rgba(0,0,0,.5);
    font-weight: bold;

    :hover{
      background: #0B9AD6;
      color: #fff;
    }
  }
`