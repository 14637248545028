import React, { useState, useEffect } from 'react'
import logo from '../../Img/logo.png'
import { animateScroll as Scroll } from 'react-scroll';
import { Link } from 'react-router-dom'
import { FaAngleLeft } from 'react-icons/fa'
import {
  Nav,
  NavbarContainer,
  NavLogo,
  MoboIcon,
  NavMenu,
  NavItem,
  NavLinks,
  Brand,
  Hamburger,
  Links,
  TopIcon
} from './NavElement'

const Navbar = (props) => {

  const toggleHome = () => {
    Scroll.scrollToTop()
  }

  const [scrollNav, setScrollNav] = useState(false);
  const [navOpen, setNavOpen] = useState(false)

  const toggle = () => {
    setNavOpen(!navOpen)
  }

  const changeNav = () => {
    if (window.scrollY >= 80) {
      setScrollNav(true)
    }
    else
      setScrollNav(false)
  }
  useEffect(() => {
    window.addEventListener('scroll', changeNav)
  }, [])

  return (
    <>
      <Nav scrollNav={scrollNav}>
        <NavbarContainer id="home">
          <Link to='/'>
            <Brand >
              <NavLogo src={logo} alt="Brand Logo" />
            </Brand>
          </Link>
          <MoboIcon onClick={toggle}>
            <Hamburger navOpen={navOpen} />
          </MoboIcon>
          <NavMenu navOpen={navOpen}>
            {
              props.pathName === '/' && <NavItem>
                <NavLinks to='/'
                  onClick={toggleHome}
                  smooth={true}
                  duration={500}
                  spy={true}
                  exect='true'
                  offset={-80}
                >Home</NavLinks>
              </NavItem>
            }
            {
              props.pathName !== '/' &&
              <NavItem>
                <Links to='/'
                >Home</Links>
              </NavItem>
            }
            {/* {
              props.pathName === '/' &&
              <NavItem>
                <NavLinks to='projects'
                  smooth={true}
                  duration={500}
                  spy={true}
                  exect='true'
                  offset={-80}>Project</NavLinks>
              </NavItem>
            } */}
            {/* {
              props.pathName === '/' &&
              <NavItem>
                <NavLinks to='blog'
                  smooth={true}
                  duration={500}
                  spy={true}
                  exect='true'
                  offset={-80}
                >Blog</NavLinks>
              </NavItem>
            } */}
            <NavItem>
              <Links to='/about'
              >About Us</Links>
            </NavItem>
            <NavItem>
              <Links to='/contact'
              >Contact Us</Links>
            </NavItem>
          </NavMenu>
        </NavbarContainer>
        <TopIcon onClick={toggleHome} scrollNav={scrollNav}>
          <FaAngleLeft />
        </TopIcon>
      </Nav>
    </>
  )
}

export default Navbar
