import React from 'react'
import blogData from '../Data/BlogData'
import { AiOutlineEye } from 'react-icons/ai'
import { Container, Content, DataHolder, Detail, Head, HeadHolder, HeadTop, Icon, Title } from '../Common/CommonElement'
import {
  BlogHolder,
  BlogCard,
  BlogTitle,
  BlogCover,
  WriteHolder,
  WriteName,
  BlogDate,
  BlogData,
  WriterProfile,
} from './BlogComponent'
import { CgCalendarDates } from 'react-icons/cg'
import { Link } from 'react-router-dom'
import { PreviewAll, PreviewAllHolder } from '../Project/ProjectElement'
import { admin } from '../../utils/constants'
import moment from 'moment'

const Blog = ({ data }) => {
  console.log(data.cover)
  return (
    <>
      {
        <Container id='blog' style={{ background: '#F1F8FB' }}>
          {
            blogData.map((data, index) => (
              <HeadHolder key={index + 'teamHead'}>
                <HeadTop>
                  <Icon src={data.icon} alt="Star Logo" />
                  <Title>{data.title}</Title>
                </HeadTop>
                <Head>{data.head}</Head>
                {/* <Detail>{data.detail}</Detail> */}
              </HeadHolder>
            ))
          }
          <Content>
            <DataHolder>
              {
                blogData.map((item, index) => (
                  <BlogHolder key={index + 'blogholder'} >
                    {
                      data.map((data, index) => (
                        <Link className='blog-redirect' to={{
                          pathname: '/blog',
                          blogData: data,
                          blogWriter: admin,
                        }}
                          // target='_blank'
                          key={index + 'blogcard'}>
                          <BlogCard >
                            <BlogCover src={data.cover} alt='Cover Img' />
                            <BlogData>
                              <WriteHolder>
                                <WriterProfile src={admin.writerProfile} alt='Profile' />
                                <WriteName>{admin.name}</WriteName>
                              </WriteHolder>
                              <BlogDate><CgCalendarDates className='date-icon' />{moment(data.createdAt).format('hh:mm, DD/MM/YYYY')}</BlogDate>
                            </BlogData>
                            <BlogTitle>{data.title}</BlogTitle>
                          </BlogCard>
                        </Link>
                      ))
                    }
                  </BlogHolder>
                ))
              }
            </DataHolder>
          </Content>
          <PreviewAllHolder>
            <PreviewAll>
              <Link to='/allblog' className='link-effect'>
                <AiOutlineEye className='eye-icon' />Check Out Our All Blogs
              </Link>
            </PreviewAll>
          </PreviewAllHolder>
        </Container>
      }
    </>
  )
}

export default Blog