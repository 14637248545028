import styled from 'styled-components'

export const ServiceImgHolder = styled.div`
  @media screen and (max-width: 769px){
      display: none;
  }

`

export const ServiceImg = styled.img`
  position: relative;
  width: 100%;

`

export const ListHolder = styled.div`
  /* border: 2px solid red; */
  margin-top: 10px;
  position: relative;
  /* border: 2px solid red; */
  width: 100%;
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 10px;
  grid-row-gap: 25px;

  @media screen and (max-width: 527px){
    grid-template-columns: auto;
    /* grid-column-gap: 0px; */
    /* grid-row-gap: 0px; */
  }
`

export const ListItem = styled.div`
  /* border: 2px solid red; */
  position: relative;
  padding: 15px;
  background: #fff;
  box-shadow: 0px 0px 10px rgba(0,0,0,.1);
  display: flex;
  /* justify-content: center; */
  align-items: center;
  font-size: 20px;
  font-weight: 800;
  /* margin: 10px; */

  @media screen and (max-width: 769px){
    /* width: 100; */
  }
  @media screen and (max-width: 527px){
    width: 90%;
  }

  :hover{
    .checkIcon{
      background: #0B9AD6;
      color: #fff;
      transition: .3s ease;
    }
  }

  .checkIcon{
    border-radius: 5px;
    padding: 5px;
    background: rgba(0,119,255,.1);
    font-size: 1.5rem;
    font-weight: 800;
    margin-right: 10px;
  }

`