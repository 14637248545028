import firebase from 'firebase';

const config = {
    apiKey: "AIzaSyCRPj1ilNwPfAa95eRaYhGWcB__l3kAwR0",
    authDomain: "sr-devops.firebaseapp.com",
    databaseURL: "https://sr-devops-default-rtdb.firebaseio.com",
    projectId: "sr-devops",
    storageBucket: "sr-devops.appspot.com",
    messagingSenderId: "1095803512384",
    appId: "1:1095803512384:web:b8fa54c68381a8b806a1a5",
    measurementId: "G-2R7GWJYZSF"
};

const firebaseApp = firebase.initializeApp(config);

export default firebaseApp;