import React from 'react'
import { AiOutlineArrowRight } from 'react-icons/ai'
import heroData from '../Data/HeroData'
import {
  HeroContainer,
  HeroContent,
  HeroData,
  HeroHead,
  HeroDetail,
  HeroBtn,
  HeroImg,
  ImgHolder,
  ClipPath,
  HeroBtnHolder
  // HeroBtnHolder

} from './HeroElement'

const Hero = () => {
  return (
    <>
      <HeroContainer>
        <ClipPath />
        <HeroContent>
          {
            heroData.map((data, index) => (
              <HeroData key={index + 'HeroRight'}>
                <ImgHolder>
                  <HeroImg src={data.img} />
                </ImgHolder>
              </HeroData>
            ))
          }
          {
            heroData.map((data, index) => (
              <HeroData key={index + 'heroLeft'}>
                <HeroHead >
                  {data.head1}
                  <br></br>
                  {data.head2}
                </HeroHead>
                <HeroDetail>
                  {data.detail1}
                  <br></br>
                  {data.detail2}
                </HeroDetail>
                <HeroBtnHolder>
                  <HeroBtn to='/contact'>
                    {data.button} <AiOutlineArrowRight style={{ marginLeft: "5px" }} />
                  </HeroBtn>
                </HeroBtnHolder>
              </HeroData>
            ))
          }

        </HeroContent>
      </HeroContainer>
    </>
  )
}

export default Hero
