import React, { useState, useEffect } from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import { useLocation } from 'react-router'

const HomeContainer = (props) => {

  let location = useLocation()

  const [pathName, setPathName] = useState('')

  useEffect(() => {
    location.pathname && setPathName(location.pathname)
  }, [location.pathname])

  return (
    <>
      <Navbar pathName={pathName} />
      {
        props.children
      }
      <Footer />
    </>
  )
}

export default HomeContainer
