import styled from 'styled-components'
import { Link as LinkR } from 'react-router-dom'

export const HeroContainer = styled.div`
  /* border: 2px solid red; */
  box-sizing: border-box;
  height: 90vh;
  background: linear-gradient(to top right, #C7F9FF, #fff);
  // background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 80px;
  position: relative;
  top: 0;
  /* position: absolute; */

  @media screen and (max-width: 769px){
    padding: 0 10px;
    height: 100%;
    padding : 30px
  }
`

export const HeroContent = styled.div`
  position: relative;
  display: flex;
  justify-content: center; 
  align-items: center;

  @media screen and (max-width: 769px){
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    /* top: 80px; */
  }
`

export const HeroData = styled.div`
  position: relative;
  /* border: 2px solid red; */
  padding: 10px;
  /* top: 80px; */

  @media screen and (max-width: 769px){
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
`

export const HeroHead = styled.div`
  font-size: 48px;
  font-weight: 800;
  line-height: 58px;
  margin-bottom: 15px;
  text-align: center;

  @media screen and (max-width: 769px){
    margin-top: 10px;
    font-size: 44px;
  }
  @media screen and (max-width: 527px){
    font-size: 38px;
  }
`

export const HeroDetail = styled.div`
  font-size: 18px;
  font-weight: 500;
  color: rgba(0,0,0,.5);
  line-height: 30px;
  margin-bottom: 15px;
   text-align: center;
`

export const HeroBtnHolder = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const HeroBtn = styled(LinkR)`
  height: 45px;
  width: 150px;
  border: 0;
  background: #0B9AD6;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  box-shadow: 0px 8px 15px rgba(11, 154, 214, 0.1);
  transition: .3s ease;
  // padding: 10px;

  @media screen and (max-width: 769px){
    
  }

  :hover{
    background: rgba(0,0,0,.8);
    transform: translateY(-1px);
  }
`

export const ImgHolder = styled.div`
  /* border: 2px solid green; */
  box-sizing: border-box; 
  position: relative;
`

export const HeroImg = styled.img`
  position: relative;
  width: 100%;
  height: 100%;
`

export const ClipPath = styled.div`
  /* border: 2px solid red; */
  position: absolute;
  background: #fff;
  width: 100%;
  bottom: 0;
  height: 100%;
  clip-path: ellipse(50% 50px at 50% 100%);

  @media screen and (max-width: 769px){
    display: none;
  }
`