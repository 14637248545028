import styled from 'styled-components'

export const ProjectCard = styled.div`
  /* border: 2px solid red; */
  border: 1px solid rgba(0,0,0,.15);
  /* margin: 20px; */
  position: relative;
  border-radius: 10px;
  padding: 10px;
  width: 80%;
  display: grid;
  background: #fff;
  grid-template-columns: 40% auto;
  /* box-shadow: 0 4px 10px rgba(0,0,0,.05); */
  z-index: 10; 
  margin: 0 auto;
  /* transform: translateX(-10px); */


  @media screen and (max-width: 769px){
    display: flex;
    flex-direction: column;
    width: 100%;
  }
`

export const ProjectCover = styled.img`
  /* border: 2px solid red; */
  position: relative;
  width: 100%;
  height: 400px;
  object-fit: cover;
`

export const ProjectData = styled.div`
  /* border: 2px solid red; */
  padding-top: 10px;
`

export const ProjectTitle = styled.div`
  color: #0B9AD6;
  font-size: 24px;
  font-weight: 800;
  text-align: center;
`

export const ProjectDetails = styled.div`
  text-align: center;
  padding: 10px;

  .info-icon{
    margin-left: 10px;
    transform: translateY(5px);
    font-size: 20px;
    color: #0B9AD6;
  }
`

export const Technology = styled.div`
  /* border: 2px solid red; */
  margin-top: 10px; 
  display: flex;
  justify-content: center;
  align-items: center;
  /* text-align: center; */
`

export const TechList = styled.div``

export const AppLink = styled.div`
  margin-top: 10px;
  text-align: center;
  .applink{
    margin-left: 10px;
    text-decoration: none;
    cursor: pointer;
    color: #000;
    font-size: 24px;

    :hover{
      color: #0B9AD6;
    }
  }
`

export const ButtonHolder = styled.div`
  /* border: 2px solid red; */
  display: flex;
  justify-content: center;
  align-items: center;

  .arrow-icon{
    /* border: 2px solid red; */
    padding: 10px;
    background: #fff;
    font-size: 20px;
    margin-left: 10px;
    border-radius: 50%;
    color: #0B9AD6;
    box-shadow: 0 0 5px rgba(0,0,0,.4);
    transition: .3s ease;

    :hover{
      color: #fff;
      background: #0B9AD6;
    }
  }
`

export const Prev = styled.div``

export const Next = styled.div``

export const Round = styled.div`
  top: 100px;
  position: absolute;
  background: rgba(11, 154, 214,.5);
  /* background: #0B9AD6; */
  border-radius: 50%;
  width: 250px;
  height: 250px;
  z-index: -1;

  @media screen and (max-width: 769px){
    display: none;
  }
`

export const Square = styled.div`
  right: 75px;
  bottom: 110px;
  position: absolute;
  border-radius: 10px;
  background: rgba(214, 11, 126,.4);
  width: 750px;
  height: 250px;
  z-index: -1;

  @media screen and (max-width: 1025px){
    width: 500px;
  }
  @media screen and (max-width: 769px){
    display: none;
  }
`

export const PreviewAllHolder = styled.div`
  /* border: 2px solid red; */
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const PreviewAll = styled.div`
  /* border: 2px solid red; */
  box-shadow: 0 0 4px rgba(0,0,0,.3);
  /* margin-top: 20px; */
  padding: 10px 10px;
  border-radius: 5px;
  color: #0B9AD6;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  transition: .3s ease;

  :hover{
    color: #fff;
    background: #0B9AD6;
    transform: translateY(-5px);
  }

  .eye-icon{
    /* border: 2px solid red; */
    margin-right: 5px;
    font-size: 20px;
    transform: translateY(4px);
  }

  .link-effect{
    text-decoration: none; 
    color: #0B9AD6;
    :hover{
      color: #fff;
    }
  }

`